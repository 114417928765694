import {oneFormAction, formAction} from '../modules/form';

;( $=>{
	const
		log = console.log,
		_d = $(document),
		_w = $(window),
		load_windows = ()=>{};
	_w.on('load',load_windows);
	_d.ready(()=>{

		let
			_ssoh, // scrollspy.outerHeight()
			_ww, _wh, _ws,
			_ssmt = 20, // scrollspy margin-top
			_sspt = 70, // scrollspy padding-top
			_ss = $('.scrollspy'),
			_ssot, // scrollspy.offset().top
			_dno_ot,
			_crishka,
			iziModal = $('.iziModal'),
			header = $('#header'),
			main = $('#main'),
			footer = $('#footer'),
			scrollspy = function(){
				if( _ss.length ){
					_ssoh = _ss.outerHeight();
					_crishka = _ws + _ssmt;
					if( _ww > 992 && _crishka > _ssot ){
						if( _crishka > ( _dno_ot - _ssoh ) ) _ss.removeClass('posf').addClass('posr').css({top:(_dno_ot - ( _ssoh + _ssot ))});
						else _ss.removeClass('posr').addClass('posf').css({top:_ssmt});
					}else _ss.removeClass('posf posr').css({top:''});
				}
			},
			handleResize = ()=>{
				_ww = _w.width(),
				_wh = _w.height();

				main.css({height:_wh});
				let sectionBGImgCatalog = $('.section-bl-img-catalog');
				if( sectionBGImgCatalog.length ){
					let heightSectionBGImgCatalog = _wh - sectionBGImgCatalog.offset().top;
					let heightForm = sectionBGImgCatalog.find( 'form' ).outerHeight( !0 );
					if( _ww > 992){
						if( heightSectionBGImgCatalog > heightForm ) sectionBGImgCatalog.height( heightSectionBGImgCatalog );
						else  sectionBGImgCatalog.height( heightForm );
					}else sectionBGImgCatalog.height('');
				}
				if( _wh > 500 ) $('.fullscreen-h').innerHeight( _wh );
				else $('.fullscreen-h').removeAttr('style');
				if( _ww > 600 ){
					$('.js-full-h').each(function(){
						let _ = $(this);
						_.height(_.parents('.wr-js-full-h').height());
					});
					main.css({paddingBottom:footer.outerHeight()});
				}else{
					$('.js-full-h').height('');
					main.css({paddingBottom:''});
				}
				if( _ss.length ){
					if( _ww > 992 ) _ss.css('width',_ss.parent().width() );
					else _ss.css('width','');
				}
				/*For iziModal*/
				if( _wh < 660 ) iziModal.addClass('min-height');
				else iziModal.removeClass('min-height');
			},
			handleScroll = ()=>{
				_ws = _w.scrollTop();
				if( _ws > 200 ) $('#moveup').removeClass('scale-out');
				else $('#moveup').addClass('scale-out');
				if( _ss.length ){
					_ssot = _ss.parents('.wr-scrollspy').offset().top;
					_dno_ot = $('.dno-scrollspy').offset().top,
					scrollspy();
				}
			},
			thunk = _ => {
				if( _.hasClass( 'has-catalog' ) ) location.href='/thanks-catalog/';
				else location.href='/thanks/';
			},
			files = null;
		/*handleResize init*/
		handleResize();
		_w.resize(handleResize);
		/*handleScroll init*/
		handleScroll();
		_w.scroll(handleScroll);

		$('.form').each(function(){
			formAction( $(this), thunk, files );
		});

		$('.btn-slide').click(function(e){
			e = e || window.event;
			e.stopPropagation();
			e.preventDefault();
			$( $(this).toggleClass('active').attr('href') ).slideToggle().parents('.hide-text').toggleClass('active');
		});


		/*Link-move init*/
		$('.link-move').click(function(e){
			e.preventDefault();
			$('html,body').animate({'scrollTop':$( $(this).attr('href') ).offset().top-20},"slow");
		});

		$('.lazy').lazy({
			effect: "fadeIn",
			effectTime: 2000,
			threshold: 0,
			enableThrottle: true,
			throttle: 250,
			delay: 2000
		});




		/*Start Popup iziModal*/
		let _iziModalSimple = $('.iziModalSimple');
		if( _iziModalSimple.length ){
			console.log('iziModalSimple init');
			_iziModalSimple.each( function(){
				let form_effect = oneFormAction();
				$(this).iziModal({
					history: !1,
					width: 960,
					transitionIn: 'fadeInDown',
					transitionOut: 'fadeOutUp',
					bodyOverflow: !0,
					focusInput: !1,
					overlayColor: 'rgba(0,0,0,.8)',
					onOpened(modal){
						setTimeout(()=>{
							modal.$element.find('.form').each( function(){ form_effect( $(this), thunk, files ) } );
							$('.switcher').click(function(){
								if( $(this).attr('for') === 'switcher-2' ) $('.switcher-input').fadeIn();
								else $('.switcher-input').fadeOut();
							});
						},100);
					}
				});
			});
			$('.btn-popup-simple').click(function(e){
				e.preventDefault();
				$( $(this).attr('href') ).iziModal('open');
			});
		}
		let _iziProjects = $('#iziModal-projects');
		if( _iziProjects.length ){
			let url_project = '';
			console.log('iziProjects init');
			_iziProjects.iziModal({
				history: !1,
				width: 960,
				transitionIn: 'fadeInDown',
				transitionOut: 'fadeOutUp',
				bodyOverflow: !0,
				overlayColor: 'rgba(0,0,0,.8)',
				focusInput: !1,
				onOpening(modal){
					modal.startLoading();
					$.get(url_project).
						done(function(data) {
							setTimeout(()=>{
								_iziProjects.find('.wr-content').html(data);
								setTimeout(()=>{
									load_windows();
									modal.$element.find('.form').each( function(){ formAction( $(this), thunk, files ) } );
									modal.stopLoading();
								},500);
							},500);
						});
				}
			});
			$('.btn-popup-ajax').click(function(e){
				e.preventDefault();
				url_project = $(this).attr('href');
				_iziProjects.iziModal('open');
			});
		}
		/*end Popup iziModal*/

		let
			i = 0,
			repl_arr = ['Кирпича', 'Керамзитобетона', 'Пенобетона', 'Газобетона'];
		setInterval( ()=>{
			i++;
			i=i<repl_arr.length?i:0;
			$('.replaceman').fadeOut(function(){
				$(this).text(repl_arr[i]).fadeIn();
			});
		},1500);
		/*ENd*/
	});
})(jQuery);